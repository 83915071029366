import React from 'react'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const InquireComponent = loadable(() => import('../../components/Inquire/Inquire'))
const SEO = loadable(() => import('../../components/SEO/SEO'))

import './Inquire.scss'

const InquireTemp = ({ pageContext: { data, metaData } }: any): JSX.Element => {
  return (
    <Layout>
      <SEO title={metaData?.title ? metaData?.title : 'Inquire - Ayala Land Premier'} description={metaData?.description} />
      <div className="inquire-content">
        <h1 style={{ display: 'none' }}>Inquire or schedule a visit</h1>
        <InquireComponent contacts={data} />
      </div>
    </Layout>
  )
}

export default InquireTemp
